import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useHistory } from "react-router-dom";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import ProductList from "../components/products/ProductList";
import bannerImage from "../assets/rdb.jpg";
import { getAddresses } from "../api/apiServices";

const Homepage = () => {
  const [comingSoonProducts, setComingSoonProducts] = useState([]);
  const [bestsellersProducts, setBestsellersProducts] = useState([]);
  const [liquidsProducts, setLiquidsProducts] = useState([]);
  const [recentlyAddedProducts, setRecentlyAddedProducts] = useState([]);
  const [bobbysCornerProducts, setBobbysCornerProducts] = useState([]);
  const [rareComboProducts, setRareComboProducts] = useState([]);
  const [prebookingProducts, setPrebookingProducts] = useState([]);
  const [compilationProducts, setCompilationProducts] = useState([]);
  const [latestReleasesProducts, setLatestReleasesProducts] = useState([]);
  const [onDiscountProducts, setOnDiscountProducts] = useState([]);
  const [preOwnedProducts, setPreOwnedProducts] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    checkAddressAndFetchProducts();
  }, []);

  const checkAddressAndFetchProducts = async () => {
    try {
      const addressResponse = await getAddresses();
      const addresses = addressResponse.data.success;
      if (addresses.length ===0  && localStorage.getItem("userId")) {
        history.replace("/addaddress");
      } else {
        fetchAllProducts();
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
      fetchAllProducts();
      history.replace("/");
    }
  };

  const fetchAllProducts = async () => {
    try {
      setLoading(true);
      const tags = [
        {
          tag: "Coming Soon",
          combo: "",
          count: 10,
          setter: setComingSoonProducts,
        },
        {
          tag: "Bestseller",
          combo: "",
          count: 10,
          setter: setBestsellersProducts,
        },
        {
          tag: "On Discount",
          combo: "",
          count: 10,
          setter: setOnDiscountProducts,
        },
        {
          tag: "Prebooking",
          combo: "",
          count: 10,
          setter: setPrebookingProducts,
        },
        {
          tag: "Latest Releases",
          combo: "",
          count: 10,
          setter: setLatestReleasesProducts,
        },
        {
          tag: "Rare",
          combo: "true",
          count: 10,
          setter: setRareComboProducts,
        },
        {
          tag: "",
          combo: "",
          count: 10,
          category: "Compilation",
          setter: setCompilationProducts,
        },
        {
          count: 10,
          condition_type: "Preowned",
          setter: setPreOwnedProducts,
        },
        {
          tag: "liquids",
          combo: "",
          count: 10,
          setter: setLiquidsProducts,
          isLiquid: true,
        },
        { tag: "", combo: "", count: 10, setter: setRecentlyAddedProducts },
      ];

      const productPromises = tags.map(
        async ({
          tag,
          combo,
          count,
          setter,
          isLiquid,
          category,
          condition_type,
        }) => {
          let res;
          if (isLiquid) {
            res = await axios.post(
              `${process.env.REACT_APP_BASEURL}/admin/product/getrecentproducts`,
              { product_type: "Liquid", count: 3 },
              null
            );
          }
          // else if (category) {

          // }
          else {
            res = await axios.post(
              `${process.env.REACT_APP_BASEURL}/admin/product/getallproduct`,
              { tag, count, combo, category, condition_type },
              null
            );
          }

          if (res.data.success) {
            setter(Object.values(res.data.data).map((item) => item));
          } else {
            if (res.data.data === "no record found") {
              // enqueueSnackbar(res.data.data, { variant: "info" });
            } else {
              console.log("Error Occurred");
              // enqueueSnackbar(res.data.message, { variant: "error" });
            }
          }
        }
      );

      await Promise.all(productPromises);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageWrapper>
        <ProductList
          products={comingSoonProducts}
          name="Coming Soon"
          hideButton
          disabled
        />
        <ProductList products={onDiscountProducts} name="On Discount" />

        <ProductList products={bestsellersProducts} name="Bestseller" />
        <ProductList products={prebookingProducts} name="Prebooking" />
        <ProductList products={latestReleasesProducts} name="Latest Releases" />
        <ProductList products={rareComboProducts} name="Rare & Combos" />
        <ProductList products={compilationProducts} name="Compilations" />
        <ProductList
          products={preOwnedProducts}
          name="Preowned and Old Pressings"
        />
        <ProductList products={recentlyAddedProducts} name="Recently Added" />
        <ProductList
          products={liquidsProducts}
          name="Pancham Vinyl Record Cleaner"
          hideButton
        />
      </PageWrapper>
    </>
  );
};

export default Homepage;
