import React from "react";

const Sitemap = () => {
  return (
    <div style={{ margin: "40px", fontFamily: "Arial, sans-serif" }}>
      <h1>Seitenverzeichnis (Sitemap)</h1>
      <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
        <li>
          <a href="https://www.panchamrecords.com/">Startseite</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products">Products</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/account">Account</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/about-us">About Us</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/tnc">Tnc</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/refund-policy">
            Refund Policy
          </a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/privacy-policy">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/cancellation-policy">
            Cancellation Policy
          </a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/contact-us">Contact Us</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/orders">Orders</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/signup">Signup</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/cart">Cart</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/checkout">Checkout</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/login">Login</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/search">Search</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/addaddress">Addaddress</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/my-address">My Address</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/payment-success">
            Payment Success
          </a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/payment-failure">
            Payment Failure
          </a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/26">Product 26</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/27">Product 27</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/28">Product 28</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/29">Product 29</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/30">Product 30</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/31">Product 31</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/32">Product 32</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/33">Product 33</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/34">Product 34</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/35">Product 35</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/36">Product 36</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/37">Product 37</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/38">Product 38</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/39">Product 39</a>
        </li>
        <li>
          <a href="https://www.panchamrecords.com/products/40">Product 40</a>
        </li>
      </ul>
    </div>
  );
};

export default Sitemap;
