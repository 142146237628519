import { useEffect, useState, useRef } from "react";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { BsBookmarkHeart, BsFillBookmarkHeartFill } from "react-icons/bs";
import axios from "axios";
import { useSnackbar } from "notistack";
import Navbar from "../components/navbar/Navbar";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  postAddProductToCartService
} from "../api/apiServices";
import BackButton from "../components/BackButton";

const ProductDetails = () => {
  const location = useLocation();
  const { productId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [imagesArray, setImagesArray] = useState([]);
  const auth = localStorage.getItem("authToken");
const userId = localStorage.getItem("userId");
  const [activeIndex, setActiveIndex] = useState(null);
   const galleryRef = useRef(null);

   const handleImageClick = () => {
     if (galleryRef.current) {
       galleryRef.current.fullScreen();
     }
   };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const history = useHistory();

  useEffect(() => {
    if (id) {
      getProduct();
    }
  }, [id]);

  const getProduct = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getproduct`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setProduct(res.data.data);

          setImagesArray(
            res.data.data.product_images.map((imageUrl, index) => ({
              original: `https://pancham-bucket.s3.amazonaws.com/${imageUrl}`,
              thumbnail: `https://pancham-bucket.s3.amazonaws.com/${imageUrl}`,
            }))
          );
        } else {
          enqueueSnackbar("Failed", { variant: "error" });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const addProductToCart = async (product_id) => {
    const data = { product_id: parseInt(product_id), qty: 1 };


    postAddProductToCartService(data)
      .then((response) => {
        if (response.data.success) {
            enqueueSnackbar("Product Added to Cart", { variant: "success" });
          // history.push("/cart");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


 function getProductType(data) {
   const { product_type, type } = data;

   if (product_type === "CD") {
     const cdType = type.cdType;
     const cdTypeKeys = Object.keys(cdType).filter(
       (key) => cdType[key] === true
     );
     if (cdTypeKeys.length > 0) {
       return cdTypeKeys.join(", ") + (cdType.type ? ` ${cdType.type}` : "");
     }
   } else if (product_type === "Casette") {
     return type.casetteType;
   } else if (product_type === "Vinyl Record") {
     const vinylRecordType = type.vinylRecordType;
     return `${vinylRecordType.recordCat1} - ${vinylRecordType.rpm} RPM - ${vinylRecordType.color}`;
   }

   return "Type not specified";
 }

  return (
    <>
      <div className="px-[4%] md:px-[10%] pb-2">
        <Navbar />
        <div className="pt-32 sm:pt-30">
          <BackButton />
          {product && (
            <>
              <div className="flex justify-center items-center pt-5 sm:pt-3 pb-2">
                <main className="grid grid-rows-1 sm:grid-cols-2 gap-2 sm:gap-10 ">
                  {imagesArray && (
                    <section className="relative bg-black/[0.05] p-2  flex items-center justify-center rounded-lg">
                      <ImageGallery
                        ref={galleryRef}
                        items={imagesArray}
                        lazyLoad
                        showPlayButton={false}
                        onClick={handleImageClick}
                      />
                    </section>
                  )}

                  <section className="p-7 px-10 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-3 sm:gap-5 ">
                    <div className="flex flex-col gap-2">
                      <h1 className=" text-2xl sm:text-4xl font-bold">
                        {product?.title}
                      </h1>
                      <p className=" text-gray-600 text-sm sm:text-base">
                        {product?.description}
                      </p>
                      {/* <div className="flex items-center gap-1">
              <span className="text-xs text-gray-400">
                ({product?.rating}) Rating
              </span>
            </div> */}
                    </div>

                    <div className="flex flex-col gap-2">
                      <h2 className="text-lg font-semibold">About Product</h2>
                      {product &&
                        product.product_type === "Liquid" &&
                        product?.details?.some(
                          (detail) =>
                            detail.key !== "" || detail.description !== ""
                        ) && (
                          <>
                            <ul className="flex gap-5">
                              <div>
                                {product.details.map((detail) => (
                                  <li>
                                    <span className="text-gray-500 text-md">
                                      {detail.key}
                                      {": "}
                                    </span>
                                    {detail.description}
                                  </li>
                                ))}
                              </div>
                            </ul>
                            <h2 className="text-lg font-semibold">
                              Instructions for use
                            </h2>
                            <span>
                              1) Clean the record with a dry microfiber cloth to
                              remove excess dry dust .
                            </span>
                            <span>
                              2) Spray this record cleaning liquid nicely and
                              make sure the record gets completely wet .
                            </span>
                            <span>
                              3) After 2-3 minutes , clean the record with a
                              soft cotton cloth . The record will dry in a
                              couple of minutes . will be nice and shiny !
                            </span>
                            <span>
                              4) After 4-5 times cleaning , any kind of dusty
                              record will get clean and shiny . you need not
                              clean the record 4-5 times at once. One rule which
                              i follow - clean the record everytime i play .
                            </span>
                            <span>
                              Following is the link to the youtube video on
                              instructions for use
                              <br />
                              <a
                                class="font-bold text-amber-600 underline"
                                target="_blank"
                                href="https://www.youtube.com/watch?v=8NCPidupldU"
                              >
                                WATCH VIDEO
                              </a>
                            </span>
                          </>
                        )}

                      {product && product.product_type !== "Liquid" && (
                        <>
                          {product.product_type && (
                            <ul className="flex gap-5">
                              <li>
                                <span className="text-gray-500 text-sm">
                                  Product:{" "}
                                </span>
                                {product.product_type}
                              </li>
                            </ul>
                          )}
                          <ul className="flex gap-5">
                            <div>
                              {product.category && (
                                <li>
                                  <span className="text-gray-500 text-sm">
                                    Category:{" "}
                                  </span>
                                  {product.category}
                                </li>
                              )}
                              {product.label && (
                                <li>
                                  <span className="text-gray-500 text-sm">
                                    Label:{" "}
                                  </span>
                                  {product.label}
                                </li>
                              )}
                            </div>
                            <div>
                              {product.condition_type && (
                                <li>
                                  <span className="text-gray-500 text-sm">
                                    Condition:{" "}
                                  </span>
                                  {product.condition_type}
                                </li>
                              )}
                              {product.year && (
                                <li>
                                  <span className="text-gray-500 text-sm">
                                    Year:{" "}
                                  </span>
                                  {product.year}
                                </li>
                              )}
                            </div>
                          </ul>
                          <ul className="flex gap-5">
                            <div>
                              {getProductType(product) && (
                                <li>
                                  <span className="text-gray-500 text-sm">
                                    Type:{" "}
                                  </span>
                                  {getProductType(product)}
                                </li>
                              )}
                              {product.country &&
                                product.country.toLowerCase() !== "unknown" && (
                                  <li>
                                    <span className="text-gray-500 text-sm">
                                      Country:{" "}
                                    </span>
                                    {`Made in ${product.country}`}
                                  </li>
                                )}
                            </div>
                            <div>
                              {product.language && (
                                <li>
                                  <span className="text-gray-500 text-sm">
                                    Language:{" "}
                                  </span>
                                  {product.language}
                                </li>
                              )}
                            </div>
                          </ul>
                          <ul className="flex gap-5">
                            <div>
                              {product?.catalogue_number && (
                                <li>
                                  <span className="text-gray-500 text-sm">
                                    Catalogue Number:{" "}
                                  </span>
                                  {product?.catalogue_number}
                                </li>
                              )}
                            </div>
                          </ul>
                          {product.condition_type === "Preowned" &&
                            product.condition_details &&
                            product.condition_details.length > 0 && (
                              <ul className="flex gap-5">
                                <div>
                                  {product.condition_details.map(
                                    (detail, index) => (
                                      <li key={index}>
                                        <span className="text-gray-500 text-sm">
                                          {detail.key}:{" "}
                                        </span>
                                        {detail.description}
                                      </li>
                                    )
                                  )}
                                </div>
                              </ul>
                            )}
                        </>
                      )}
                    </div>

                    <div className="flex gap-2 items-center pb-10 sm:pb-0">
                      Price:
                      <span className="ms-1 text-xl sm:text-2xl text-amber-600">
                        ₹{product?.selling_price}
                      </span>
                      {product?.mrp > product?.selling_price && (
                        <span className="text-sm text-gray-600 line-through">
                          ₹{product.mrp}
                        </span>
                      )}
                    </div>

                    <div
                      className={`w-full   flex gap-4 items-center   flex-wrap  `}
                    >
                      <button
                        className={`btn-rounded-secondary flex items-center gap-2 text-sm ${
                          product.qty_available === 0 || !userId
                            ? "disabled:cursor-not-allowed"
                            : ""
                        }`}
                        disabled={product.qty_available === 0}
                        onClick={() => {
                          if (!userId) {
                            history.push("/login");
                          } else if (product.qty_available > 0) {
                            addProductToCart(product.product_id);
                          }
                        }}
                      >
                        {product.qty_available <= 0 ? (
                          "Sold Out"
                        ) : !userId ? (
                          "Login to Shop"
                        ) : (
                          <>
                            <HiOutlineShoppingBag /> Add to Cart
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                </main>
              </div>
              {product && product.product_type !== "Liquid" && (
                <>
                  <section className="p-5 px-10 mt-5 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-3 sm:gap-5 ">
                    <div className="flex flex-col gap-2  ">
                      <h2 className="  text-lg font-semibold">Artists</h2>
                      <ul className="flex gap-5">
                        <div>
                          {product?.artists?.length > 0 && (
                            <li>
                              <span className="text-gray-500 text-md">
                                Artists:{" "}
                              </span>
                              {product.artists.join(", ")}
                            </li>
                          )}
                          {product?.lyricists?.length > 0 && (
                            <li>
                              <span className="text-gray-500 text-md">
                                Lyricists:{" "}
                              </span>
                              {product.lyricists.join(", ")}
                            </li>
                          )}
                          {product?.music_director?.length > 0 && (
                            <li>
                              <span className="text-gray-500 text-md">
                                Music Directors:{" "}
                              </span>
                              {product.music_director.join(", ")}
                            </li>
                          )}
                        </div>
                      </ul>
                      {product?.details?.some(
                        (detail) =>
                          detail.key !== "" || detail.description !== ""
                      ) && (
                        <>
                          <h2 className="  text-lg font-semibold">
                            About Product
                          </h2>
                          <ul className="flex gap-5">
                            <div>
                              {product.details.map((detail) => (
                                <li>
                                  <span className="text-gray-500 text-md">
                                    {detail.key}
                                    {": "}
                                  </span>
                                  {detail.description}
                                </li>
                              ))}
                            </div>
                          </ul>
                        </>
                      )}
                    </div>
                  </section>
                  <section className="p-5 mb-5 px-10 mt-5 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-3 sm:gap-5">
                    <div className="space-y-4">
                      <h2 className="  text-lg font-semibold">
                        {product.product_type === "Vinyl Record"
                          ? "Records"
                          : product.product_type === "CD"
                          ? "CDs"
                          : "Cassetes"}{" "}
                        ({product?.cd_details?.length})
                      </h2>

                      {product?.cd_details?.map((cd, index) => (
                        <div
                          key={cd?.id}
                          className="border rounded-xl overflow-hidden"
                        >
                          <div
                            className="flex justify-between items-center p-4 cursor-pointer bg-gray-100 transition duration-300 ease-out"
                            onClick={() => toggleAccordion(index)}
                          >
                            <div>{cd?.name}</div>
                            <div>
                              {cd?.tracks?.length} Tracks {"  >"}
                            </div>
                          </div>

                          <div
                            className={`overflow-hidden transition-max-height duration-300 ease-out ${
                              activeIndex === index ? "" : "max-h-0"
                            }`}
                          >
                            <ul className="p-4">
                              <div>
                                {Object.entries(
                                  cd?.tracks?.reduce((acc, track) => {
                                    const side = track.side || "Unknown";
                                    acc[side] = acc[side] || [];
                                    acc[side].push(track);
                                    acc[side].sort((a, b) => a.index - b.index);
                                    return acc;
                                  }, {}) ?? {}
                                ).map(([side, tracks]) => (
                                  <div key={side} className="mb-5">
                                    <h2 className="mb-2 mt-1 text-md font-semibold">{`Tracks for Side ${side}`}</h2>
                                    <ul>
                                      {tracks.map((track, index) => (
                                        <div key={track.id}>
                                          <h2 className="mt-1 text-md font-semibold">
                                            {index + 1}. {track.title}
                                          </h2>
                                          <ul className="flex gap-5">
                                            <div>
                                              {track.singers.length > 0 && (
                                                <li>
                                                  <span className="text-gray-500 text-md">
                                                    Artists:{" "}
                                                  </span>
                                                  {track.singers.join(", ")}
                                                </li>
                                              )}
                                              {track.lyricists.length > 0 && (
                                                <li>
                                                  <span className="text-gray-500 text-md">
                                                    Lyricists:{" "}
                                                  </span>
                                                  {track.lyricists.join(", ")}
                                                </li>
                                              )}
                                              {track.music_directors.length >
                                                0 && (
                                                <li>
                                                  <span className="text-gray-500 text-md">
                                                    Music Directors:{" "}
                                                  </span>
                                                  {track.music_directors.join(
                                                    ", "
                                                  )}
                                                </li>
                                              )}
                                            </div>
                                          </ul>
                                        </div>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
