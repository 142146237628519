import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import SingleProduct from "../components/products/SingleProduct";
import BackButton from "../components/BackButton";
import IconButton from "@mui/material/IconButton";
import SortIcon from "@mui/icons-material/Sort";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // For Price Low to High
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha"; // For alphabetical sorting
import ClearIcon from "@mui/icons-material/Clear"; // Clear filters icon
import Tooltip from "@mui/material/Tooltip";
import { Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material";

const ProductListing = () => {
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [languageFilter, setLanguageFilter] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // State to hold the search query
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const auth = localStorage.getItem("authToken");

  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category") || "";

  useEffect(() => {
    getProducts(category);
  }, [category]);

  const getProducts = async (tag) => {
    try {
      setLoading(true);
      let requestData = { tag };

      if (category === "Recently Added") {
        requestData = {};
      }

      if (category === "Rare & Combos") {
        requestData = { tag: "Rare", combo: "true" };
      }
      if (category === "Compilations") {
        requestData = { category: "Compilation" };
      }
       if (category === "Preowned and Old Pressings") {
         requestData = { product_type: "Preowned" };
       }
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getallproduct`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );

      if (res.data.success) {
        const productList = Object.values(res.data.data).map((item) => item);
        setProducts(productList);
        setOriginalProducts(productList);
        // setProducts(Object.values(res.data.data).map((item) => item));
      } else {
        if (res.data.data === "no record found") {
          enqueueSnackbar(res.data.data, { variant: "info" });
        } else {
          console.log("Error Occurred");
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  // Filter change handlers
  const handleCategoryChange = (e) => setCategoryFilter(e.target.value);
  const handleLanguageChange = (e) => setLanguageFilter(e.target.value);
  const handleProductTypeChange = (e) => setProductTypeFilter(e.target.value);
  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  // Search products by title
  const searchProducts = () => {
    const filteredProducts = originalProducts.filter((product) =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setProducts(filteredProducts);
  };
  // Function to sort products
  const sortProducts = (order, type = "price") => {
    let sortedProducts;
    if (type === "price") {
      sortedProducts = [...products].sort((a, b) => {
        return order === "asc"
          ? a.selling_price - b.selling_price
          : b.selling_price - a.selling_price;
      });
    } else if (type === "alphabet") {
      sortedProducts = [...products].sort((a, b) => {
        return order === "asc"
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title);
      });
    }
    setProducts(sortedProducts);
  };

  // Function to reset all filters
  const clearFilters = () => {
    setCategoryFilter("");
    setLanguageFilter("");
    setProductTypeFilter("");
    setSearchTerm("");
    setProducts([...originalProducts]);
  };
  // New API to apply filters
  const applyFilters = async () => {
    try {
      setLoading(true);

      // Dynamically build the requestData based on selected filters
      let requestData = {};
      if (categoryFilter) requestData.category = categoryFilter;
      if (languageFilter) requestData.language = languageFilter;
      if (productTypeFilter) requestData.product_type = productTypeFilter;
      if (category) requestData.tag = category;
      if (category === "Rare & Combos") {
        // requestData = { tag: "Rare", combo: "true" };
        requestData.tag = "Rare"
        requestData.combo = "true"
      }

      if (category === "Prebooking and Latest Releases") {
        requestData.tag = "Prebooking";
      }
      if (category === "Recently Added") {
        requestData.tag = null;
      }
if (category === "Preowned and Old Pressings") {
  requestData.condition_type = "Preowned";
        requestData.tag = null;

}
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getfilterproducts`,
        requestData,
        {
          headers: { Authorization: `Bearer ${auth}` },
        }
      );

      if (res.data.success) {
        setProducts(Object.values(res.data.data));
      } else {
        setProducts([]);
        // enqueueSnackbar(res.data.message || "No records found", {
        //   variant: "info",
        // });
      }
    } catch (error) {
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   // Call the applyFilters function when any filter changes
  //   if (categoryFilter || languageFilter || productTypeFilter) {
  //     applyFilters();
  //   }
  // }, [categoryFilter, languageFilter, productTypeFilter]);
  useEffect(() => {
    // Call the applyFilters function when any filter changes, including empty filters (All)
    applyFilters();
  }, [categoryFilter, languageFilter, productTypeFilter]);
  useEffect(() => {
    searchProducts();
  }, [searchTerm]);
  return (
    <PageWrapper>
      <div>
        <h2 className="text-2xl font-bold mb-5">{category}</h2>
        <BackButton />

        {/* Search Input */}
        <div className="mb-4">
          <TextField
            label="Search Products"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
          />
        </div>
        {/* Filter Select Fields */}
        <div className="flex flex-wrap justify-end gap-2 mb-4">
          {/* <div className="flex justify-start gap-4 mb-4"> */}
          {/* Category Filter */}
          <FormControl variant="outlined" sx={{
            width: {
              xs: "100%",  // Full width on mobile (xs)
              sm: "100%",  // Full width on tablets (sm)
              md: "120px",  // Auto width on desktops (md and up)
            },
          }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={categoryFilter}
              onChange={handleCategoryChange}
              label="Category"
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={"Movie Sound Track"}>
                Movie Sound Track
              </MenuItem>
              <MenuItem value={"Compilation"}>Compilation</MenuItem>
              <MenuItem value={"Album"}>Album</MenuItem>
              {/* <MenuItem value={"Other"}>Other</MenuItem> */}
            </Select>
          </FormControl>

          {/* Language Filter */}
          <FormControl variant="outlined" sx={{
            width: {
              xs: "100%",  // Full width on mobile (xs)
              sm: "100%",  // Full width on tablets (sm)
              md: "120px",  // Auto width on desktops (md and up)
            },
          }}>
            <InputLabel>Language</InputLabel>
            <Select
              value={languageFilter}
              onChange={handleLanguageChange}
              label="Language"
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={"Hindi"}>Hindi</MenuItem>
              <MenuItem value={"English"}>English</MenuItem>
              <MenuItem value={"Marathi"}>Marathi</MenuItem>
              <MenuItem value={"Punjabi"}>Punjabi</MenuItem>
              <MenuItem value={"Bengali"}>Bengali</MenuItem>
              <MenuItem value={"Tamil"}>Tamil</MenuItem>
              <MenuItem value={"Telugu"}>Telugu</MenuItem>
              <MenuItem value={"Malayalam"}>Malayalam</MenuItem>
            </Select>
          </FormControl>

          {/* Product Type Filter */}
          <FormControl variant="outlined" sx={{
            width: {
              xs: "100%",  // Full width on mobile (xs)
              sm: "100%",  // Full width on tablets (sm)
              md: "140px",  // Auto width on desktops (md and up)
            },
          }}>
            <InputLabel>Product Type</InputLabel>
            <Select
              value={productTypeFilter}
              onChange={handleProductTypeChange}
              label="Product Type"
            >
              <MenuItem value={""}>All</MenuItem>
              {/*<MenuItem value={"Liquid"}>Liquid</MenuItem>*/}
              <MenuItem value={"CD"}>CD</MenuItem>
              <MenuItem value={"Cassette"}>Cassette</MenuItem>
              <MenuItem value={"Vinyl Record"}>Vinyl Record</MenuItem>
            </Select>
          </FormControl>
          {/* Sort and Filter Buttons */}
          {/* Price: Low to High */}
          <Tooltip title="Sort by Price: Low to High" placement="top" arrow>
            <IconButton
              onClick={() => sortProducts("asc", "price")}
              color="primary"
              aria-label="Sort Price Low to High"
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>

          {/* Price: High to Low */}
          <Tooltip title="Sort by Price: High to Low" placement="top" arrow>
            <IconButton
              onClick={() => sortProducts("desc", "price")}
              color="primary"
              aria-label="Sort Price High to Low"
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>

          {/* Alphabetical Sorting */}
          <Tooltip title="Sort Alphabetically: A-Z" placement="top" arrow>
            <IconButton
              onClick={() => sortProducts("asc", "alphabet")}
              color="primary"
              aria-label="Sort Alphabetically A-Z"
            >
              <SortByAlphaIcon />
            </IconButton>
          </Tooltip>

          {/* Clear All Filters */}
          <Tooltip title="Clear All Filters" placement="top" arrow>
            <IconButton
              onClick={clearFilters}
              color="secondary"
              aria-label="Clear All Filters"
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </div>

        {products.length > 0 ? (
          <main className="relative grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {products.map((product) => (
              <SingleProduct
                key={product.product_id}
                product={{
                  _id: product.product_id,
                  qty: 0,
                  name: product.title,
                  description: product.description,
                  brand: product.category,
                  category: "Sports", // Replace with actual category if available
                  gender: "Men", // Replace with actual gender if available
                  weight: "200g", // Replace with actual weight if available
                  quantity: product.qty_available, // Replace with actual quantity if available
                  image: product.product_images[0],
                  rating: 4.5, // Replace with actual rating if available
                  price: product.mrp,
                  newPrice: product.selling_price,
                  trending: true, // Replace with actual trending status if available
                  label: product.label,
                  type: product.product_type,
                }}
              />
            ))}
          </main>
        ) : (
          <p className="font-sans text-4xl font-bold uppercase tracking-wide text-gray-300 text-center w-full py-32">
            Nothing to Show!
          </p>
        )}
      </div>
    </PageWrapper>
  );
};

export default ProductListing;
